import { QcHeader } from "@/types";
import { Masks, Paths, UON, globalConfig, headers, operator, setOtherFilters, upsertWithDataReturn } from "@/utils";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import { useApi } from "@/requests/useApi";
import store from "@/store";
import { formatDate, formatDateLocale } from "@/utils/configuration/formatters-config";
import i18n from "@/i18n";
import moment from "moment";
import { codeConfirmation } from "../auth";
import { buildJobs, buildRole } from "../thirdService";

export const userList = async (filter: any, offset? : any , limit?: any) => {
  const operators = [];

  const qc_header: QcHeader = {
    qc: {
      queryId: "user-lease-forge-user-with-usergroup",
      offset: offset || 0,
      limit: limit || 100,
      parameters: {
        ...filter,
      },
    },
  };
   
  const LEASE_FORGE_USER = "user_lease_forge_user";
  const USER_GROUP = "userGroups";
  const PARTY_PARTY_ROLE_NETWORK = "party_party_role_network"


  if (filter?.username) {
    operators.push(
      operator({
        val: filter.username,
        table: LEASE_FORGE_USER,
        dbField: "username",
      }).LIKE
    );
  }

  if (filter?.firstName) {
    operators.push(
      operator({
        val: filter.firstName,
        table: LEASE_FORGE_USER,
        dbField: "first_name",
      }).LIKE
    );
  }

  if (filter?.lastName) {
    operators.push(
      operator({
        val: filter.lastName,
        table: LEASE_FORGE_USER,
        dbField: "last_name",
      }).LIKE
    );
  }


  if (filter?.email) {
    operators.push(
      operator({
        val: filter.email,
        table: LEASE_FORGE_USER,
        dbField: "email",
      }).LIKE
    );
  }

  if (filter?.status) {
    operators.push(
      operator({
        val: filter.status,
        table: LEASE_FORGE_USER,
        dbField: "enabled",
      }).EQUAL
    );
  }

  if (filter?.notExpired) {
    operators.push(
      operator({
        val: filter.notExpired,
        table: LEASE_FORGE_USER,
        dbField: "account_not_expired",
      }).EQUAL
    );
  }

  if (filter?.notLocked) {
    operators.push(
      operator({
        val: filter.notLocked,
        table: LEASE_FORGE_USER,
        dbField: "account_not_locked",
      }).EQUAL
    );
  }

  if (filter?.credintialsNotExpired) {
    operators.push(
      operator({
        val: filter.credintialsNotExpired,
        table: LEASE_FORGE_USER,
        dbField: "credentials_not_expired",
      }).EQUAL
    );
  }

  if (filter?.externalReference) {
    operators.push(
      operator({
        val: filter.externalReference,
        table: LEASE_FORGE_USER,
        dbField: "external_reference",
      }).LIKE
    );
  }

  if (filter?.userGroups) {
    operators.push(
      operator({
        val: filter.userGroups,
        table: USER_GROUP,
        dbField: "group_code::text",
      }).LIKE
    );
  }

  if (filter?.networkNodes) {
    operators.push(
      operator({
        val: filter.networkNodes,
        table: PARTY_PARTY_ROLE_NETWORK,
        dbField: "network_node_id",
      }).LIKE
    );
  }

  setOtherFilters(operators, qc_header);

  return await api().get(settings.api_query_url, {
    headers: headers({
      qc: JSON.stringify(qc_header.qc),
    }),
  });

}

export async function buildSelectedUser(user: any, payload: any) {


  if (user.userGroups.length === 0 || user.userGroups === undefined) {

    user.userGroups = [
      {
        value : '',
        label : ''
      }
    ]
  }
  else{
    user.userGroups = user.userGroups.map((item: any) => {
      return {
        config: item,
        value : item.group.resourceUid,
        label:
          item.label?.toString() !== ""
            ? i18n.global.t("middleOffice.decision.profil." + item.group.resourceUid)
            : "",
      };
    });
  }
  const whoCreatedfilter = {
    username : payload._who_created
    };
  const whoCreated = (await userList(whoCreatedfilter)).data[0];
  const whoModifiedfilter = {
  username : payload._who_modified
  };
  const whoModified = (await userList(whoModifiedfilter)).data[0];
  const EmptyUserEdit = {
    ...user,
    _when_created: formatDateLocale(payload._when_created, i18n.global.t("mask.fullFormat"), 'fr', 'DD/MM/YYYY'),
    _when_modified: formatDateLocale(payload._when_modified, i18n.global.t("mask.fullFormat"), 'fr', 'DD/MM/YYYY'),
    _who_created: whoCreated ? whoCreated.first_name + ' ' + whoCreated.last_name : "Anonymous",
    _who_modified: whoModified ? whoModified.first_name + ' ' + whoModified.last_name : "Anonymous"
  }
  return EmptyUserEdit
}

export const getTeamFromUserId = async (resourceUid: string) => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "team-of-user",
      offset: 0,
      limit: 100,
      parameters: { user_code: resourceUid }
    }
  };
  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  return data.map((el: { id: string, manager_code: string }) => {
    return {
      label: el.id,
      value: el.id,
      config: {
        manager_code: el.manager_code
      }

    }
  })
}

export const getTeamMembersByManagerCode = async (managerCode: string) => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "user-team-members",
      offset: 0,
      limit: 100,
      parameters: { manager: managerCode }
    }
  };
  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  return data

}
export async function SaveParty(body: any) {
  body.roles= await buildRole(body.roles) 
  body.roles?.forEach((role: any) => {
    delete role.facilityPreferences;
  });
  if (body.jobs && body.jobs.length > 0 && body.jobs[0].employerName) {
    await buildJobs(body, true)
    body.jobs?.map((job: any) => {
        if (job.jobLocation && !job.jobLocation.resourceUid) {
            delete job.jobLocation
        }
    })
  } else {
        delete body.jobs
        body.jobs = []
    }
  body.birthDate = addHours(body.birthDate)
  const data = await upsertWithDataReturn(UON.SystemUid.odmParty, body, "person");
  return data
}
export async function SaveUser(body: any) {
  delete body._when_created
  delete body._when_modified
  delete body._who_created
  delete body._who_modified
  if (body.passwordExpirationDate) {
    body.passwordExpirationDate = addHours(body.passwordExpirationDate)
  }
    body.userGroups = body.userGroups.map((resourceUid: any) => ({
      businessData: null,
      daaq: "/",
      group: {
        objectType: "odm.user.securitygroup",
        resourceUid: resourceUid.value
          ? resourceUid.value
          : resourceUid.group.resourceUid,
        systemUid: "odm-user",
      },
      objectType: "odm.user.usergroup",
      resourceUid: resourceUid.config?.resourceUid ? resourceUid.config.resourceUid : null,
      systemUid: "odm-user",
    }));
  const data = await upsertWithDataReturn(UON.SystemUid.odmUser, body, "leaseforgeuser");
  return data
}

export async function getPartyRoleNetwork(payload: any) {
  const data = await api().get(`/${UON.SystemUid.odmParty}/api/1/${UON.SystemUid.odmParty}/networknode/${payload.networkNode}/partyrolenetwork/`);
  return data
}

export async function savePartyRoleNetwork(payload: any) {
  const body = payload.body
  const data = await api().post(`/${UON.SystemUid.odmParty}/api/1/${UON.SystemUid.odmParty}/networknode/${payload.networkNode}/partyrolenetwork/`, body);
  return data
}

export async function updatePartyRoleNetwork(payload: any) {
  const body = payload
  const data = await api().put(`/${UON.SystemUid.odmParty}/api/1/${UON.SystemUid.odmParty}/partyrolenetwork/${payload.resourceUid}/`, body);
  return data
}

export async function UpdateTeamMembers(user: any) {
  const managers = store.state.userModule.managers
  const manager = store.state.userModule.manager
  const team_id = managers.filter((item: any) => item.manager_code === manager?.id)[0]?.team_id
  if(team_id &&  team_id.length>0 ){
    const { response } = await useApi(UON.SystemUid.odmUser, `${Paths.team}/${team_id}/`).get();
    const clone = response;

    if (clone.members.filter((item: any) => item.member.resourceUid === user.resourceUid).length === 0) {
      clone.members.push({
        "businessData": null,
        "daaq": "/",
        "objectType": "odm.user.teammember",
        "systemUid": "odm-user",
        "validity": {},
        "member": {
          "resourceUid": user.resourceUid,
          "objectType": "odm.user.leaseforgeuser",
          "systemUid": "odm-user",
        }
      })
    }


    clone.members.map((item: any) => {
      return {
        ...item.validity = {}

      }
    })
    clone.validity = {}
    const data = await api().put(
      `/${UON.SystemUid.odmUser}/api/1/${UON.SystemUid.odmUser}/${Paths.team}/${team_id}/`,
      clone
    );

  }
}

export const getManagerByTeam = async (managerCode: string) => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "user-team-manager",
      offset: 0,
      limit: 100,
      parameters: { manager_code: managerCode }
    }
  };
  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  return data

}
export const addHours = (date: string) => {
  return moment(date,'DD/MM/YYYY').format('YYYY-MM-DD')
};



export async function getPerson(personId: string) {
  const { response } = await useApi(UON.SystemUid.odmParty, `${Paths.person}/${personId}/`).get();
  return response
}


export async function resetPasswordFromAdmin(username: string, newPassword: any) {
  const bodyRequest = {
    "newPassword": newPassword,
    "applicationName": "TWDDistribution"
  }
  const { data } = await api().put(`/${UON.SystemUid.odmAuthentication}/api/1/${UON.SystemUid.odmAuthentication}/change-password/${username}/`, bodyRequest);
  if (!data.smsCodeValidated) {
    await codeConfirmation(data);
  }

  return data
}


export async function addUserAuthorities(userId: string) {
  const bodyRequest = {
    "daaqComponent": {
      "objectType": "odm.user.daaq",
      "systemUid": "odm-user",
      "resourceUid": "DEFAULT_DAAQ"
    }
  }
  const data = await api().post(`/${UON.SystemUid.odmUser}/api/1/${UON.SystemUid.odmUser}/leaseforgeuser/${userId}/userdaaq/`, bodyRequest);
  return data
}

export async function getUserLogs(userName: string) {
  const { response } = await useApi(UON.SystemUid.odmAuthentication, `${Paths.actions}/${userName}/`).get();
  return response
}