import store from "@/store"
import { Order, OrderItem, PartyAddress, Role } from "@/types"
import { Masks, deepClone, deleteAttributes, deleteAttributesRecursively, deleteNonRequiredAttributes, formatDateRecursively, mountSelectFields, unmountSelectFields, unmountSelectFieldsV2 } from "@/utils"
import { formatDate, formatDateLocale } from "@/utils/configuration/formatters-config"
import moment from "moment"
import { createOrderOrch } from "./orderApi"
import { handlePersonToCreate } from "../thirdOrchestration"

export const saveOrderOrch = async (order: any,orderParties?:any) => {
    const orderClone = deepClone(order)
    orderClone.orderNetworks.network.resourceUid=orderClone.orderNetworks.network.resourceUid.id
    orderClone.orderNetworkNodes.networkNode.resourceUid=orderClone.orderNetworkNodes.networkNode.resourceUid.id
    const handleOrderParties = handleOrderPartiesOrch(deepClone(orderParties))
    orderClone.orderParties=handleOrderParties
    if (orderClone.orderCommissions  && orderClone.orderCommissions.length>0) {
        for (const commission of orderClone.orderCommissions){
            delete commission.role
            if(commission.adjustAmount){
                commission.adjustAmount={
                    amount: typeof commission.adjustAmount === 'string' ? commission.adjustAmount : commission.adjustAmount.amount,
                    currency:"EUR"
                    }
           }
              
        }
    }
    orderClone.previsionDeliveryDate=orderClone.previsionDeliveryDate?formatDateLocale(orderClone.previsionDeliveryDate, 'DD/MM/YYYY', 'fr'):''
    orderClone.effectiveDeliveryDate=orderClone.effectiveDeliveryDate?formatDateLocale(orderClone.effectiveDeliveryDate, 'DD/MM/YYYY', 'fr'): moment(new Date()).format("YYYY-MM-DD")
    handleOrderItem(orderClone)
    //formatDateRecursively(order, 'DD/MM/YYYY', Masks.dateMask)
    orderClone.financing = handleOrderFinancing(orderClone.orderParties, orderClone)
    delete orderClone.alertList

    if (orderClone.resourceUid === '') {
        delete order.resourceUid
    }

    if( orderClone.commissionProductPack&&orderClone.commissionProductPack.resourceUid===''){
        delete orderClone.commissionProductPack
    }
    
    delete orderClone.config  
    const orderCreated = await createOrderOrch(orderClone)

    return orderCreated
}

 function handelDocumentsParty(documentsParty:any){
    for (const document of documentsParty) {
                   delete document.creationDate
                   delete document.addedBy
                   document.documentType =  
                   document.documentType&& document.documentType.type&& document.documentType.type.value
                   ? { resourceUid : document.documentType.type.value}
                   : null
       }
  }
  
const handleOrderPartiesOrch = (orderParties:any) => {
    const listOrderParties:any[]=[]
    for(const orderPartie of orderParties){
        const associtedPartie :any={
            objectType: "odm.order.orderassociatedparty",
            systemUid: "odm-order",
            orderParty:{
                objectType: "odm.order.orderparty",
                systemUid: "odm-order",
                role:{
                    objectType: "odm.order.orderpartyrole",
                    systemUid: "odm-order",
                    resourceUid:orderPartie.role_code
                },
                party:{},
            }

        }
        if(orderPartie.associatedParty&&orderPartie.associatedParty.third&&orderPartie.associatedParty.third.person
            &&orderPartie.associatedParty.third.type&&orderPartie.associatedParty.third.type.id&&
            orderPartie.associatedParty.third.type.id === "party-FrenchPerson"
        ){

            unmountSelectFieldsV2(orderPartie.associatedParty.third.person, [
                "title",
                "nationality",
                "role",
                "status",
                "currentMaritalStatus",
                "currentMatrimonialRegime",
                "nature",
                "periodicity",
                "classifications",
                "gender",
                "birthplace",
                "addresses"
              ]);
              /*if(!orderPartie.associatedParty.third.person.resourceUid){
                handlePersonToCreate(orderPartie.associatedParty.third)
              }*/
              if(!(orderPartie.associatedParty.third.person.roles && orderPartie.associatedParty.third.person.roles.length>0)){
                orderPartie.associatedParty.third.person.roles=  handleRole(orderPartie.role_code)
              }
            unmountSelectFieldsV2(orderPartie.associatedParty.third.person.classifications, ["partyClass"]);
            orderPartie.associatedParty.third.person.birthplace= buildBirthplace(orderPartie.associatedParty.third.person.birthplace)

            const person =handlePersonToSave(orderPartie.associatedParty.third.person)
            person.objectType="odm.party.party.person.frenchperson"
            person.systemUid="odm-party"
            associtedPartie.orderParty.party={
                objectType: "odm.party.party.person.frenchperson",
                systemUid: "odm-party",
                rawData:person
            }
            if(person.resourceUid){
                associtedPartie.orderParty.party.resourceUid=person.resourceUid
            }
            
        }
        if(orderPartie.associatedParty&&orderPartie.associatedParty.third&&orderPartie.associatedParty.third.organization
            &&orderPartie.associatedParty.third.type&&orderPartie.associatedParty.third.type.id&&
            orderPartie.associatedParty.third.type.id === "party-FrenchOrganization"
        ){
            unmountSelectFieldsV2(orderPartie.associatedParty.third.organization, [
                "legalCategory",
                "activitySector",
                "partyClass",
                "codeNACE",
                "role",
                "status",
                "contacts",
                "addresses",
                "correspondents",
                "financialExposures"
              ]);
              if(!(orderPartie.associatedParty.third.organization.roles && orderPartie.associatedParty.third.organization.roles.length>0)){
                orderPartie.associatedParty.third.organization.roles=  handleRole(orderPartie.role_code)
              }
              unmountSelectFieldsV2(orderPartie.associatedParty.third.organization.classifications, ["partyClass"]);
            const organization =handleOrganisationToSave(orderPartie.associatedParty.third.organization)
            organization.objectType ="odm.party.party.organization.frenchorganization"
            organization.systemUid= "odm-party"
            
            associtedPartie.orderParty.party={
                objectType: "odm.party.party.organization.frenchorganization",
                systemUid: "odm-party",
                rawData:organization
            }
            if(orderPartie.associatedParty.third.organization.resourceUid){
                associtedPartie.orderParty.party.resourceUid=orderPartie.associatedParty.third.organization.resourceUid
            }
            
        }
        if(orderPartie.resourceUid){
            associtedPartie.resourceUid=orderPartie.resourceUid
        }
        if(orderPartie.orderPartyResourceUid){
            associtedPartie.orderParty.resourceUid=orderPartie.orderPartyResourceUid
        }


        listOrderParties.push(associtedPartie)
    }
    return listOrderParties

}

export function handlePersonToSave(person:any){
    person.objectType="odm.party.party.person.frenchperson"
    if(!person.gender.resourceUid){
        delete person.gender
    }
    if(person.taxGeographicZone){
        person.taxGeographicZone={
            objectType: "odm.party.geographiczone",
        systemUid: "odm-party",
        resourceUid: person.taxGeographicZone,
        } 
    }

    if(person.classifications&&person.classifications.length>0){
        person.classifications= buildClassification(person.classifications)
    }else{
        person.classifications=[]
    }
    /*if(person.birthplace){
        person.birthplace= buildBirthplace(person.birthplace)
    }*/

    if(person.roles && person.roles.length>0){
        person.roles=buildRole(person.roles)
    }else{
        person.roles=[] 
    }

   if(person.addresses && person.addresses.length>0){
    const listAddress=[]
    for (const partyaddress of person.addresses){
        if(partyaddress.address.type&&partyaddress.address.type.type&&partyaddress.address.type.type.value&&partyaddress.address.type.type.value.length>0){
            partyaddress.address.type={objectType:"odm.party.addresstype",
            systemUid:"odm-party",
            resourceUid:partyaddress.address.type.type.value[0]
            }
        }else{
            partyaddress.address.type=null
        }
        if(partyaddress.livingSituation.type&&partyaddress.livingSituation.type.value){
            partyaddress.livingSituation={
                objectType: "odm.party.livingsituation",
                systemUid: "odm-party",
                resourceUid:partyaddress.livingSituation.type.value
            }

        }
        else{
            partyaddress.livingSituation = null 
        }
        if(partyaddress.address.postalCode){
            delete partyaddress.address.postalCode.region
            delete partyaddress.address.postalCode.inseeCode
            delete partyaddress.address.postalCode.department
            delete partyaddress.address.postalCode.city
            delete partyaddress.address.postalCode.type
            partyaddress.address.postalCode.resourceUid=partyaddress.address.postalCode.postalCode
        }
        if(partyaddress.address){
            delete partyaddress.address.isDisabled
        }
        if(partyaddress.address && partyaddress.address.postalCode && partyaddress.address.postalCode.postalCode){
            listAddress.push(partyaddress)
        }
    }
    person.addresses=listAddress
        /*if(!(person.addresses[0]&&person.addresses[0].address&&person.addresses[0].address.postalCode&&person.addresses[0].address.postalCode.postalCode)){
            person.addresses=[]
        }*/
    }

    if (person.revenues && person.revenues.length > 0 && person.revenues[0]?.analysisDate) {
        buildAnalysis(person)
    } else {
        person.revenues = []
    }

    if (person.homemakers !== "") {
        buildBusinessDataPerson(person)
    }
    if (person.birthDate !== "") {
        buildBirthDatePerson(person)
    }

    if (person.currentMaritalStatus) {
        buildSituation(person)
    } else {
        person.maritalStatuses = []
    }
    if (person.jobs && person.jobs.length > 0 && person.jobs[0].employerName) {
        buildJobs(person, true)
        person.jobs?.map((job: any) => {
            if ((job.jobLocation && job.jobLocation.postalCode && job.jobLocation.postalCode.type
                 && job.jobLocation.postalCode.type.value === "") || (job.jobLocation && job.jobLocation.postalCode && job.jobLocation.postalCode.type==="")) {
              delete job.jobLocation;
            }
        })
    } else {

        person.jobs = []
    }
    delete person.currentMaritalStatus
    delete person.currentMatrimonialRegime
    delete person.homemakers
    delete person.retirementPassage
    delete person.birthChildren
    delete person.removedSituation
    delete person.nationality

    person.roles?.forEach((role: Role) => {
        role.preferences?.push(...role.facilityPreferences);
        delete role.facilityPreferences;
    });

    if (person.financialExposures) {
        person.financialExposures?.forEach((financialExposure: any) => {
            if (financialExposure.outstandingType && financialExposure.outstandingType.value === "") {
                delete financialExposure.outstandingType
            }
            if (financialExposure.financialInstitution && financialExposure.financialInstitution.value === "") {
                delete financialExposure.financialInstitution
            }
        })

        person.financialExposures = buildFinancialExposures(person.financialExposures)
    }
    person.objectType = 'odm.party.party.person.frenchperson'

    if (person.contacts) {
         buildPersonContacts(person);
    }
    const ids = person.ids?.filter((el: any) => el.emissionDate !== "")
    if (ids && ids.length > 0) {
        person.ids?.forEach((item: any) => {
            item.emissionDate = item.emissionDate ? formatDateLocale(item.emissionDate, 'DD/MM/YYYY', 'fr') : '';
            item.validity.from = item.emissionDate;
            item.validity.until = item.validity.until ? formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr') : '';
            item.documentType.resourceUid = item.documentType.type.value || item.documentType.resourceUid;

            delete item.documentType.type
            delete item.isDisabled
        });
    } else {
        person.ids = []
    }

    if(person.correspondents&&person.correspondents.length>0){
        for(const correspondent of person.correspondents){
            for(const contactCorrespondent of correspondent.contactCorrespondent){
                if(contactCorrespondent.contactMechanism){
                    delete contactCorrespondent.contactMechanism.type
                    delete contactCorrespondent.contactMechanism.isDisabled
                    delete contactCorrespondent.contactMechanism.blocked
                }
                
            }
            if((correspondent.qualificationType&&correspondent.qualificationType.type&&
                correspondent.qualificationType.type.value) ||(correspondent.qualificationType&&correspondent.qualificationType.resourceUid )){
                    correspondent.qualificationType.resourceUid = correspondent.qualificationType.type?.value || correspondent.qualificationType.resourceUid
                    delete correspondent.qualificationType.type
                }else{
                    correspondent.qualificationType=null
                }
        }

    }else{
        person.correspondents=[]  
    }


    if (!person.accounts) {
        person.accounts = []
    }

   

    if(!(person.birthplace && person.birthplace.resourceUid)){
        delete person.birthplace
    }
    if(!(person.gender && person.gender.resourceUid)){
        delete person.gender
    }

    
    if(!(person.title && person.title.resourceUid)){
        delete person.title
    }
    if (person.documents && person.documents.length){
        handelDocumentsParty(person.documents)
    }

    cleanAttribute(person)

    return person

}

function cleanAttribute(third: any) {
    delete third.relations
    delete third.codeApe
    delete third.type
    if (third.status?.resourceUid == "") {
        delete third.status
    }
}
 function buildRole(roles: any) {
    const r: any = []
    roles.forEach((role: any) => {
        r.push({
            resourceUid: role.resourceUid,
            role: {
                resourceUid: role.role?.resourceUid,
                objectType: 'odm.party.role'
            },
            status: {
                resourceUid: role.status?.resourceUid || 'ACTIVATED',
                objectType: 'odm.party.rolestatus'
            },
            validity: {
                from: role.validity?.from ? formatDateLocale(role.validity.from, 'DD/MM/YYYY', 'fr') : formatDateLocale(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY', 'fr'),
                until: role.validity?.until ? formatDateLocale(role.validity.until, 'DD/MM/YYYY', 'fr') : '',
            },
            nonSolicitation: role.nonSolicitation,
            daaq: "/",
            preferences: [],
            facilityPreferences: [],
            objectType: 'odm.party.partyrole'
        })
    })

    return r
}

function buildClassification(classifications: any[]) {
    const classif: any[] = []
    classifications.filter((item: any) => !!item.partyClass?.resourceUid).forEach((classification: any) => {
        const partyClassification: any = {
            objectType: "odm.party.partyclassification",
            partyClass: {
                resourceUid: classification.partyClass?.resourceUid,
                objectType: "odm.party.partyclass",

            },
            validity: {
                from: classification.validity?.from ? formatDateLocale(classification.validity.from, 'DD/MM/YYYY', 'fr') : '',
                until: classification.validity?.until ? formatDateLocale(classification.validity.until, 'DD/MM/YYYY', 'fr') : '',
            },
            daaq: "/",
            classificationDate: classification.classificationDate ? formatDateLocale(classification.classificationDate, 'DD/MM/YYYY', 'fr') : '',

        }
        if (classification.resourceUid) {
            partyClassification.resourceUid = classification.resourceUid
        }
        classif.push(partyClassification)
    })
    return classif
}

 function buildBirthplace(birthplace: any) {
    const place:any = {
        resourceUid: birthplace.resourceUid || birthplace.postalCode,
        objectType: 'odm.party.postalcode.frenchpostalcode'
    }
    return place
}

 function buildAnalysis(person: any) {
    const analyse: any = []
    person
        .revenues
        .filter((revenue: any) => revenue.analysisDate !== "" && !revenue.resourceUid)
        .forEach((revenue: any) => {
            const detailAnalyse: any = []
            revenue.detail.forEach((detailItem: any) => {
                if (detailItem.nature.resourceUid || detailItem.nature.type) {
                    const detail = {
                        nature: {
                            resourceUid: detailItem.nature.resourceUid || detailItem.nature.type,
                            objectType: 'odm.party.expenseincometype'
                        },
                        periodicity: {
                            resourceUid: detailItem.periodicity.resourceUid,
                            objectType: 'odm.party.expenseincomeperiodicity'
                        },
                        value: {
                            currency: "EUR",
                            amount: detailItem.value
                        },
                        label: detailItem.type,
                        validity: {
                            from: detailItem.validity.from ? formatDateLocale(detailItem.validity.from, 'YYYY-MM-DD', 'fr') : '',
                            until: detailItem.validity.until ? formatDateLocale(detailItem.validity.until, 'YYYY-MM-DD', 'fr') : '',
                        },
                        daaq: store.state.authModule?.daaq
                    }
                    detailAnalyse.push(detail)
                }
            })
            analyse.push({
                label: '',
                analysisDate: revenue.analysisDate ? formatDateLocale(revenue.analysisDate, 'YYYY-MM-DD', 'fr') : '',
                status: {
                    resourceUid: 'NEW',
                    objectType: 'odm.party.revenuesanalysisstatus'
                },
                yearlyExpensesTotal: {
                    currency: "EUR",
                    amount: 0
                },
                yearlyIncomeTotal: {
                    currency: "EUR",
                    amount: 0
                },
                daaq: store.state.authModule?.daaq,
                detail: detailAnalyse
            })
        })
    delete person.analysis
    deleteAttributesRecursively(person.revenues, ['type'])
    if (analyse.length > 0) {
        person.revenues = analyse
    }

    person
        .revenues
        .filter((r: any) => !!r.resourceUid)
        .forEach((r: any) => {
            delete r.isDisabled
            r.analysisDate = r.analysisDate ? formatDateLocale(r.analysisDate, 'YYYY-MM-DD', 'fr') : '',
                r.detail.forEach((d: any) => {
                    d.daaq = store.state.authModule?.daaq,
                        d.value = {
                            currency: "EUR",
                            amount: d.value
                        },
                        d.validity = {
                            from: d.validity.from ? formatDateLocale(d.validity.from, 'YYYY-MM-DD', 'fr') : '',
                            until: d.validity.until ? formatDateLocale(d.validity.until, 'YYYY-MM-DD', 'fr') : '',
                        }
                        if(!(d.nature&&d.nature.resourceUid)){
                            d.nature=null
                        }
                        if(!(d.periodicity&&d.periodicity.resourceUid)){
                            d.periodicity=null
                        }
                })
        })
}

 function buildBusinessDataPerson(person: any) {
    const businessDataPerson = {
        homemakers: person.homemakers,
        retirementPassage: person.retirementPassage,
        birthChildren: person.birthChildren,
    }
    person.businessData = businessDataPerson
}
 function buildBirthDatePerson(person: any) {
    person.birthDate = person.birthDate ? formatDateLocale(person.birthDate, 'DD/MM/YYYY', 'fr') : moment().format(Masks.dateMask)
    delete person.age
}
 function buildCitizenshipsPerson(person: any) {
    person.citizenships = []
    if (person.nationality.type?.value || person.nationality.resourceUid) {
        const citizenship = {
            country: {
                resourceUid: person.nationality.type?.value || person.nationality.resourceUid,
                objectType: 'odm.party.country'
            },
            daaq: store.state.authModule?.daaq
        }
        person.citizenships.push(citizenship)
    }
    delete person.nationality
}

function buildSituation(person: any) {
    let maritalStatusesUID = null
    if (person.maritalStatuses[0] && person.maritalStatuses[0].resourceUid) {
        maritalStatusesUID = person.maritalStatuses[0].resourceUid
    }
    person.maritalStatuses = []
    if(!!person.currentMaritalStatus.resourceUid && person.currentMaritalStatus.resourceUid === "SINGLE"){
        const maritalStatuses = {
            maritalStatus: {
                resourceUid: person.currentMaritalStatus.resourceUid,
                objectType: 'odm.party.maritalstatus',
            },
            matrimonialRegime: {
                resourceUid: 'NONE',
                objectType: 'odm.party.matrimonialregime',
            },
            daaq: store.state.authModule?.daaq,
            resourceUid: maritalStatusesUID
        }
        person.maritalStatuses.push(maritalStatuses)
    }else if (!!person.currentMaritalStatus.resourceUid && !!person.currentMatrimonialRegime.resourceUid) {
        let maritalStatuses: any = {}
        if (maritalStatusesUID) {
            maritalStatuses = {
                maritalStatus: {
                    resourceUid: person.currentMaritalStatus.resourceUid,
                    objectType: 'odm.party.maritalstatus',
                },
                matrimonialRegime: {
                    resourceUid: person.currentMatrimonialRegime.resourceUid,
                    objectType: 'odm.party.matrimonialregime',
                },
                daaq: store.state.authModule?.daaq,
                resourceUid: maritalStatusesUID

            }
        } else {
            maritalStatuses = {
                maritalStatus: {
                    resourceUid: person.currentMaritalStatus.resourceUid,
                    objectType: 'odm.party.maritalstatus',
                },
                matrimonialRegime: {
                    resourceUid: person.currentMatrimonialRegime.resourceUid,
                    objectType: 'odm.party.matrimonialregime',
                },
                daaq: store.state.authModule?.daaq,
                resourceUid: maritalStatusesUID

            }
        }

        person.maritalStatuses.push(maritalStatuses)
    }
    delete person.currentMaritalStatus
    delete person.currentMatrimonialRegime
}

function buildJobs(person: any, saving?: boolean, daaqResourceUid?: string) {
    const jobsPerson: any = []
 
    const jobsList = person.jobs.filter((item: any) => !!item.employerName)
    for (const el of jobsList) {
            unmountSelectFieldsV2(el, ['occupationType', 'activity', 'positionType', 'contractType'])
            if (el.jobLocation) {
                delete el.jobLocation.isDisabled
			}

        jobsPerson.push({
            occupationType: (el.occupationType?.resourceUid || el.occupationType?.type?.value) ? el.occupationType : null,
            activity: el.activity,
            positionType: el.positionType,
            employerName: el.employerName,
            nbHour: el.nbHour,
            validity: {
                from: el.validity?.from ? saving ? isDateValid(el.validity?.from) ? formatDateLocale(el.validity?.from, 'DD/MM/YYYY', 'fr') : formatDateLocale(el.validity?.from, 'YYYY-MM-DD', 'fr')  : el.validity?.from : '',
                until: el.validity?.until ? saving ? isDateValid(el.validity?.until) ? formatDateLocale(el.validity?.until, 'DD/MM/YYYY', 'fr') : formatDateLocale(el.validity?.until, 'YYYY-MM-DD', 'fr')   : el.validity?.until : '',
            },
            employerCreationDate: el.employerCreationDate ? saving ? isDateValid(el.employerCreationDate) ? formatDateLocale(el.employerCreationDate, 'DD/MM/YYYY', 'fr') : formatDateLocale(el.employerCreationDate, 'YYYY-MM-DD', 'fr')  : el.employerCreationDate : '',
            principal: el.principal,
            tenureDate: el.tenureDate ? saving ?  isDateValid(el.tenureDate) ? formatDateLocale(el.tenureDate, 'DD/MM/YYYY', 'fr') : formatDateLocale(el.tenureDate, 'YYYY-MM-DD', 'fr') : el.tenureDate : '',
            daaq: daaqResourceUid ?? '/',
            resourceUid: el.resourceUid || undefined,
            contractType: (el.contractType?.resourceUid || el.contractType?.type?.value) ? el.contractType : null,
            jobLocation: el.jobLocation
        })
    }
    delete person.jobs
    if (jobsPerson.length > 0) {
        person.jobs = jobsPerson
    }
    return person
}
function buildFinancialExposures(financialExposures: any) {
    const exposures: any[] = []
    if (financialExposures && financialExposures.length > 0) {
        financialExposures.map((item: any) => {
            const exposure :any =
            {
                resourceUid: item.resourceUid,
                objectType: item.objectType,
                systemUid: item.systemUid,
                daaq: item.daaq,
                grossOutstanding: {
                    amount: item.grossOutstanding,
                    currency: "EUR"
                },
                netOutstanding: {
                    amount: item.netOutstanding,
                    currency: "EUR"
                },
                residualValue: {
                    amount: item.residualValue,
                    currency: "EUR"
                },
                flagFinancialPool: item.flagFinancialPool,
                flagRiskPool: item.flagRiskPool,
                shareFinancialPool: item.shareFinancialPool,
                shareRiskPool: item.shareRiskPool,
                effectiveDate: item.effectiveDate ? formatDateLocale(item.effectiveDate, 'DD/MM/YYYY', 'fr') : '',
                contractReference: item.contractReference,
                contractValidity: {
                    from: item.contractValidity.from ? formatDateLocale(item.contractValidity.from, 'DD/MM/YYYY', 'fr') : '',
                    until: item.contractValidity.until ? formatDateLocale(item.contractValidity.until, 'DD/MM/YYYY', 'fr') : '',
                },
                outstandingType:{objectType: "odm.party.outstandingtype",
                systemUid: "odm-party",
                resourceUid:item.outstandingType?.value ? item.outstandingType?.value : item.outstandingType
                },
                financialInstitution:{
                    objectType: "odm.party.financialinstitution",
                    systemUid: "odm-party",
                    resourceUid:item.financialInstitution?.value ? item.financialInstitution?.value : item.financialInstitution,
                } 

            }
            if(!(exposure.outstandingType&&exposure.outstandingType.resourceUid)||(exposure.outstandingType&&exposure.outstandingType.resourceUid&&exposure.outstandingType.resourceUid.value)){
                exposure.outstandingType=null
            }
            if(!(exposure.financialInstitution&&exposure.financialInstitution.resourceUid)|| (exposure.financialInstitution&&exposure.financialInstitution.resourceUid&&exposure.financialInstitution.resourceUid.value)){
                exposure.financialInstitution=null
            }
            exposures.push(exposure)
        })

        return exposures;
    }
    else {
        return []
    }

}
async function buildOrganisationContacts(organisation: any) {
    const contacts = organisation.contacts?.filter((partyContactMechanism: any) => {
        const contactMechanism = partyContactMechanism.contactMechanism;
        return contactMechanism.emailAddress !== ""
            || contactMechanism.phoneNumber !== ""
            || contactMechanism.identifier !== ""
    })
    if (contacts && contacts.length > 0) {
        organisation.contacts?.forEach((contact: any) => {
            if(contact.contactMechanism){
                if(contact.contactMechanism.type &&contact.contactMechanism.type.id&&contact.contactMechanism.type.id==='PHONE_CONTACT'){
                    delete contact.contactMechanism.emailAddress
                    delete contact.contactMechanism.identifier
                    contact.contactMechanism.objectType='odm.party.contactmechanism.phonecontact'
                }
                if(contact.contactMechanism.type &&contact.contactMechanism.type.id&&contact.contactMechanism.type.id==='EMAIL_CONTACT'){
                    delete contact.contactMechanism.phoneNumber
                    delete contact.contactMechanism.identifier
                    delete contact.contactMechanism.acceptSMS
                    contact.contactMechanism.objectType='odm.party.contactmechanism.emailcontact'
                }
                if(contact.contactMechanism.type &&contact.contactMechanism.type.id&&contact.contactMechanism.type.id==='MESSENGER_CONTACT'){
                    delete contact.contactMechanism.emailAddress
                    delete contact.contactMechanism.phoneNumber
                    delete contact.contactMechanism.acceptSMS
                    contact.contactMechanism.objectType='odm.party.contactmechanism.messengercontact'
                }
                delete contact.contactMechanism.type
                delete contact.contactMechanism.isDisabled
                delete contact.contactMechanism.blocked
                
                if(contact.contactMechanism.validity ){
                    contact.contactMechanism.validity.from=   contact.contactMechanism.validity.from ? formatDateLocale(contact.contactMechanism.validity.from, 'DD/MM/YYYY', 'fr') : '';
                    contact.contactMechanism.validity.until=  contact.contactMechanism.validity.until ? formatDateLocale(contact.contactMechanism.validity.until, 'DD/MM/YYYY', 'fr'):''
                }
            }
            deleteNonRequiredAttributes(contact, ["resourceUid", "objectType","systemUid","contactMechanism", "daaq"]);
        })
    } else {
        delete organisation.contacts
        organisation.contacts = []
    }
}
async function buildPersonContacts(person: any) {
    const contacts = person.contacts?.filter((partyContactMechanism: any) => {
        const contactMechanism = partyContactMechanism.contactMechanism;
        return contactMechanism.emailAddress !== ""
            || contactMechanism.phoneNumber !== ""
            || contactMechanism.identifier !== ""
    })
    if (contacts && contacts.length > 0) {
        person.contacts?.forEach((contact: any) => {
            if(contact.contactMechanism){
                if(contact.contactMechanism.type &&contact.contactMechanism.type.id&&contact.contactMechanism.type.id==='PHONE_CONTACT'){
                    delete contact.contactMechanism.emailAddress
                    delete contact.contactMechanism.identifier
                    contact.contactMechanism.objectType='odm.party.contactmechanism.phonecontact'
                }
                if(contact.contactMechanism.type &&contact.contactMechanism.type.id&&contact.contactMechanism.type.id==='EMAIL_CONTACT'){
                    delete contact.contactMechanism.phoneNumber
                    delete contact.contactMechanism.identifier
                    delete contact.contactMechanism.acceptSMS
                    contact.contactMechanism.objectType='odm.party.contactmechanism.emailcontact'
                }
                if(contact.contactMechanism.type &&contact.contactMechanism.type.id&&contact.contactMechanism.type.id==='MESSENGER_CONTACT'){
                    delete contact.contactMechanism.emailAddress
                    delete contact.contactMechanism.phoneNumber
                    delete contact.contactMechanism.acceptSMS
                    contact.contactMechanism.objectType='odm.party.contactmechanism.messengercontact'
                }

                if(contact.contactMechanism.validity ){
                    contact.contactMechanism.validity.from=   contact.contactMechanism.validity.from ? formatDateLocale(contact.contactMechanism.validity.from, 'DD/MM/YYYY', 'fr') : '';
                    contact.contactMechanism.validity.until=  contact.contactMechanism.validity.until ? formatDateLocale(contact.contactMechanism.validity.until, 'DD/MM/YYYY', 'fr'):''
                }
                delete contact.contactMechanism.type
                delete contact.contactMechanism.isDisabled
                delete contact.contactMechanism.blocked
            }
            deleteNonRequiredAttributes(contact, ["resourceUid", "objectType","systemUid","contactMechanism", "daaq"]);
        })
    } else {
        delete person.contacts
        person.contacts = []
    }
}
function isDateValid(dateString: any) {
    
    // Expression régulière for the  format "DD/MM/YYYY"
    const regexDMY = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
    
    return regexDMY.test(dateString) 
}
export function handleOrganisationToSave(organisation:any){
    organisation.objectType ="odm.party.party.organization.frenchorganization"
    organisation.creationDate=organisation.creationDate ? formatDateLocale(organisation.creationDate, 'DD/MM/YYYY', 'fr') : ''
    
    if(organisation.taxGeographicZone){
        organisation.taxGeographicZone={
            objectType: "odm.party.geographiczone",
        systemUid: "odm-party",
        resourceUid: organisation.taxGeographicZone,
        } 
    }
    if(organisation.classifications&&organisation.classifications.length>0){
        organisation.classifications= buildClassification(organisation.classifications)
    }else{
        organisation.classifications=[]
    }
    if(organisation.roles && organisation.roles.length>0){
        organisation.roles=buildRole(organisation.roles)
        organisation.roles?.forEach((role: Role) => {
            role.preferences?.push(...role.facilityPreferences);
            delete role.facilityPreferences;
        });
    }else{
        organisation.roles=[] 
    }
    if (organisation.contacts) {
        buildOrganisationContacts(organisation);
    }
    if(organisation.addresses && organisation.addresses.length>0){
        for (const partyaddress of organisation.addresses){
            if(partyaddress.address.type&&partyaddress.address.type.type&&partyaddress.address.type.type.value&&partyaddress.address.type.type.value.length>0){
                partyaddress.address.type={objectType:"odm.party.addresstype",
                systemUid:"odm-party",
                resourceUid:partyaddress.address.type.type.value[0]
                }
            }else{
                partyaddress.address.type=null
            }
             partyaddress.livingSituation=null
            if(partyaddress.address.postalCode){
                delete partyaddress.address.postalCode.region
                delete partyaddress.address.postalCode.inseeCode
                delete partyaddress.address.postalCode.department
                delete partyaddress.address.postalCode.city
                delete partyaddress.address.postalCode.type
                partyaddress.address.postalCode.resourceUid=partyaddress.address.postalCode.postalCode
            }
            if(partyaddress.address){
                delete partyaddress.address.isDisabled
            }
        }
        if(!(organisation.addresses[0]&&organisation.addresses[0].address&&organisation.addresses[0].address.postalCode&&organisation.addresses[0].address.postalCode.postalCode)){
            organisation.addresses=[]
        }


    }else{
        organisation.addresses=[]
    }

    if(organisation.correspondents&&organisation.correspondents.length>0){
        for(const correspondent of organisation.correspondents){
            for(const contact of correspondent.contactCorrespondent){
                if(contact.contactMechanism){
                    if(contact.contactMechanism.type &&contact.contactMechanism.type.id&&contact.contactMechanism.type.id==='PHONE_CONTACT'){
                        delete contact.contactMechanism.emailAddress
                        delete contact.contactMechanism.identifier
                        contact.contactMechanism.objectType='odm.party.contactmechanism.phonecontact'
                    }
                    if(contact.contactMechanism.type &&contact.contactMechanism.type.id&&contact.contactMechanism.type.id==='EMAIL_CONTACT'){
                        delete contact.contactMechanism.phoneNumber
                        delete contact.contactMechanism.identifier
                        delete contact.contactMechanism.acceptSMS
                        contact.contactMechanism.objectType='odm.party.contactmechanism.emailcontact'
                    }
                    if(contact.contactMechanism.type &&contact.contactMechanism.type.id&&contact.contactMechanism.type.id==='MESSENGER_CONTACT'){
                        delete contact.contactMechanism.emailAddress
                        delete contact.contactMechanism.phoneNumber
                        delete contact.contactMechanism.acceptSMS
                        contact.contactMechanism.objectType='odm.party.contactmechanism.messengercontact'
                    }
                    delete contact.contactMechanism.type
                    delete contact.contactMechanism.isDisabled
                    delete contact.contactMechanism.blocked
                }
                
            }
            if((correspondent.qualificationType&&correspondent.qualificationType.type&&
                correspondent.qualificationType.type.value) ||(correspondent.qualificationType&&correspondent.qualificationType.resourceUid )){
                    correspondent.qualificationType.resourceUid = correspondent.qualificationType.type?.value || correspondent.qualificationType.resourceUid
                    delete correspondent.qualificationType.type
                }else{
                    correspondent.qualificationType=null
                }
            
        }

    }else{
        organisation.correspondents=[]  
    }

    if (organisation.financialExposures) {
        organisation.financialExposures?.forEach((financialExposure: any) => {
            if (financialExposure.outstandingType && financialExposure.outstandingType.value === "") {
                delete financialExposure.outstandingType
            }
            if (financialExposure.financialInstitution && financialExposure.financialInstitution.value === "") {
                delete financialExposure.financialInstitution
            }
        })

        organisation.financialExposures = buildFinancialExposures(organisation.financialExposures)
    }
    if(organisation.legalCategory){
        delete organisation.legalCategory.type
    }
    if ( organisation.legalCategory && Object.keys(organisation.legalCategory)?.length === 0) {
        delete  organisation.legalCategory
    }
    if(organisation.activitySector){
        delete organisation.activitySector.type
    }
    if ( organisation.activitySector && Object.keys(organisation.activitySector)?.length === 0) {
        delete  organisation.activitySector
    }
    if (organisation.facilities) {
        buildFacilities(organisation.facilities, organisation.roles);
    }
    if (organisation.balanceSheets) {
        for (const item of organisation.balanceSheets) {

            let status = '';
            if (item.status?.id) {
                status = item.status?.id
            }
            if (item.status?.value) {
                status = item.status?.value
            }
            const country = item.country?.id;
            item.status = {
                objectType: 'odm.party.balancesheetstatus',
                resourceUid: status
            };
            item.country = {
                objectType: 'odm.party.country',
                resourceUid: country
            };

            if (item.period) {
                item.period.until = item.period.until ? formatDateLocale(item.period.until, 'DD/MM/YYYY', 'fr') : ''
                item.period.from = item.period.from ? formatDateLocale(item.period.from, 'DD/MM/YYYY', 'fr') : ''
            }
            if (item.detail) {
                item.detail.forEach((dt: any) => {
                    const nature = dt.nature.label;
                    dt.nature = {
                        objectType: 'odm.party.balanceitemtype',
                        resourceUid: nature
                    };
                    dt.objectType = 'odm.party.balanceitem';
                    
                });
            }
            item.daaq = '/'
            if (item.supportingDocument ) {
                item.supportingDocument.systemUid= 'odm-filemngt'
            }
        }
    }
    if(organisation.relations&&organisation.relations.length>0){
        organisation.relations.forEach((item:any) => {
            item.relation = { resourceUid: item.relation?.type?.value }
            if (item.validity) {
                item.validity.from = formatDateLocale(item.validity?.from, 'DD/MM/YYYY', 'fr')
                if (item.validity.from === 'Invalid date') {
                    item.validity.from = formatDateLocale(formatDate(new Date(), '-'), 'DD/MM/YYYY', 'fr');
                }
                if (item.validity.until && item.validity.until !== 'Invalid date') {
                    item.validity.until = formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr');
                }
            }


        });
    }
    if (!organisation.accounts) {
        organisation.accounts = []
    }
    if (organisation.documents && organisation.documents.length){
        handelDocumentsParty(organisation.documents)
    }
    delete organisation.relations
    delete organisation.codeApe
    delete organisation.codeNACE

    return organisation
}

 function buildFacilities(body: any[], roles?: Role[]) {
    let index = 0
    for (const facility of body) {
        if (facility.siret) {
            delete facility.isDisabled;
            delete facility.livingSituation;
            facility.daaq = '/'
            const partyAddresses = new Array<PartyAddress>();
            partyAddresses[0] = {
                daaq: "/",
                address: facility.address
            };
            if (facility.facilityType) {
                delete facility.facilityType.type
            }
            /*if(facility.address){
                facility.address.type=null 
            }*/

        } else {
            body.splice(index, 1);
        }
        index++
    }
}
 function Relations(relation: any) {

        const relations = relation.filter((item:any) => item.relationType?.resourceUid !== '');
        relations
            .forEach((item:any) => {
                item.relation = { resourceUid: item.relation?.type?.value }
                if (item.validity) {
                    item.validity.from = formatDateLocale(item.validity?.from, 'DD/MM/YYYY', 'fr')
                    if (item.validity.from === 'Invalid date') {
                        item.validity.from = formatDateLocale(formatDate(new Date(), '-'), 'DD/MM/YYYY', 'fr');
                    }
                    if (item.validity.until && item.validity.until !== 'Invalid date') {
                        item.validity.until = formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr');
                    }
                }

            });



        return relations;

}
const handleOrderItem = (order: Order) => {
    for (const orderItem of order.orderItems || []) {
        if (orderItem.orderAssets) {
            if(orderItem.effectiveDeliveryDate){
                orderItem.effectiveDeliveryDate =  moment(orderItem.effectiveDeliveryDate ,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            }
            if(orderItem.initialDeliveryDate){
                orderItem.initialDeliveryDate =  moment(orderItem.initialDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            }
            if(orderItem.previsionDeliveryDate){
                orderItem.previsionDeliveryDate =  moment(orderItem.previsionDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            }
            const orderAsset = orderItem.orderAssets[0]
            delete orderAsset.assetTradeIn
            delete orderAsset.taxValue
            handleOrderAssets(orderItem,orderAsset)
            if(orderItem.orderAssets.length >1){
                const orderAssetTradeIn = orderItem.orderAssets[1]
                orderAssetTradeIn.flagNew=false
                orderAssetTradeIn.flagVD=false
                if((orderAssetTradeIn.assetTradeIn && orderAssetTradeIn.amountWoTax && orderAssetTradeIn.amountWoTax?.amount>0)){
                    delete  orderAssetTradeIn.assetDelivery
                    delete orderAssetTradeIn.orderAssetServices
                    handleOrderAssets(orderItem,orderAssetTradeIn)
                }else{
                    orderItem.orderAssets.splice(1, 1);
                }
            }
        }


    }
}
const handleOrderAssetServices =  (orderAssetServices: any) => {
    const ListOrderAssetServices=[]
    for(const orderAssetservice of orderAssetServices){
        delete orderAssetservice.type.type
        orderAssetservice.purchase = orderAssetservice.purchase.includes('true')
        ListOrderAssetServices.push(orderAssetservice)
    }
    return ListOrderAssetServices
}
const handleOrderAssets = async (orderItem: OrderItem,orderAsset: any) => {

    delete orderAsset.config
    if(orderAsset.orderAssetServices){
        orderAsset.orderAssetServices= handleOrderAssetServices(orderAsset.orderAssetServices)
    }
    if (orderAsset.inventoryItemCode) {
        orderAsset.assetInventory= {
            resourceUid: orderAsset.inventoryItemCode,
            objectType: 'odm.product.inventoryitem.inventoryitemvehicle.inventoryitemcar',
            systemUid: 'odm-product',
        }
        delete orderAsset.inventoryItemCode
    }
    if(orderAsset.orderAssetComponents&&orderAsset.orderAssetComponents.length>0){
        for(const orderAssetComponent of orderAsset.orderAssetComponents){
            if (orderAssetComponent.type &&  typeof orderAssetComponent.type === 'string') {
                orderAssetComponent.type= {
                    resourceUid: orderAssetComponent.type
                }
            }
            delete orderAssetComponent.config
        }

    }
    if (orderAsset.assetDelivery){
        orderAsset.assetDelivery =  handleAssetDelivery(orderAsset.assetDelivery , orderItem)
    }
    if (orderAsset.constructionDate){
        orderAsset.constructionDate =   moment(orderAsset.constructionDate , 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
    if (orderAsset.matriculationDate){
        orderAsset.matriculationDate =   moment(orderAsset.matriculationDate , 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
    
    if (orderAsset.firstCarRegistrationDate ){
        orderAsset.firstCarRegistrationDate =  moment(orderAsset.firstCarRegistrationDate , 'DD/MM/YYYY').format('YYYY-MM-DD')
    }

    if(orderAsset.brand &&orderAsset.brand.id){
        orderAsset.brand=orderAsset.brand.id
    }

    if(orderAsset.range &&orderAsset.range.id){
        orderAsset.range=orderAsset.range.id
    }

    if(orderAsset.version &&orderAsset.version.id){
        orderAsset.version=orderAsset.version.id
    }

    if(orderAsset.carBodyStyle &&orderAsset.carBodyStyle.id){
        orderAsset.carBodyStyle=orderAsset.carBodyStyle.id
    }

    if(orderAsset.engineType &&orderAsset.engineType.id){
        orderAsset.engineType=orderAsset.engineType.id
    }
    if(!(orderAsset.supplier && orderAsset.supplier.party && orderAsset.supplier.party.resourceUid)){
        orderAsset.supplier=null
    }
    delete orderAsset.proposalAssetItems
    delete orderAsset.doorNumbers
    delete orderAsset.Kilometrage

}



function handleAssetDelivery (assetDelivery?: any, orderItem?: any){
  
    if (assetDelivery&& orderItem) {
      
        if(orderItem.effectiveDeliveryDate){
            assetDelivery.effectiveDeliveryDate =  moment(orderItem.effectiveDeliveryDate,  'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
        }
        else {
            assetDelivery.effectiveDeliveryDate = null
        }
        if(orderItem.initialDeliveryDate){
            assetDelivery.initialDeliveryDate =  moment(orderItem.initialDeliveryDate,  'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
        }
        else{
            assetDelivery.initialDeliveryDate = null   
        }
        if(orderItem.previsionDeliveryDate){
            assetDelivery.previsionDeliveryDate =  moment(orderItem.previsionDeliveryDate,  'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
        }
        else{
            assetDelivery.previsionDeliveryDate = null  
        }
        return assetDelivery
    }
}


function handleOrderFinancing(saveOrderPartiesResponse: any, order: any) {
    let orderFinancingRequest:any = null
    const financingValue =  store.state.orderModule.orderFinancingModule?.financing
    if(financingValue &&  financingValue.label){
        const financingParty = saveOrderPartiesResponse.filter((party: any) => party.orderParty?.party?.resourceUid == store.state.orderModule.orderFinancingModule.financing?.associatedParty?.orderParty?.party?.resourceUid);
        orderFinancingRequest = {...financingValue };

        if (financingParty && financingParty[0] && financingParty[0].orderParty && financingParty[0].orderParty.party && financingValue) {
            orderFinancingRequest.financingOrganization = financingParty[0].orderParty
        }
        else{
            orderFinancingRequest.financingOrganization = null
        }
        if (orderFinancingRequest && (financingValue?.contribution!==null &&financingValue?.contribution!==undefined)) {
            if(financingValue?.contribution.amount!==null &&financingValue?.contribution.amount!==undefined){
                orderFinancingRequest.contribution = {
                    amount: financingValue?.contribution.amount,
                    currency: "EUR"
                }
            }else{
                orderFinancingRequest.contribution = {
                    amount: financingValue?.contribution,
                    currency: "EUR"
                }
            }
            
        }
        else{
            orderFinancingRequest.contribution = {
                amount: 0,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && (financingValue?.financedAmountWoTax!==null  &&financingValue?.financedAmountWoTax!==undefined ) ) {
            if(financingValue?.financedAmountWoTax.amount!==null &&financingValue?.financedAmountWoTax.amount!==undefined){
                orderFinancingRequest.financedAmountWoTax = {
                    amount: financingValue?.financedAmountWoTax.amount,
                    currency: "EUR"
                }
            }else{
                orderFinancingRequest.financedAmountWoTax = {
                    amount: financingValue?.financedAmountWoTax,
                    currency: "EUR"
                }
            }       
        }
        else{
            orderFinancingRequest.financedAmountWoTax = {
                amount: 0,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && (financingValue?.financedAmountWTax!=null &&financingValue?.financedAmountWTax!=undefined)) {
            if(financingValue?.financedAmountWTax.amount!==null &&financingValue?.financedAmountWTax.amount!==undefined){
                orderFinancingRequest.financedAmountWTax = {
                    amount: financingValue?.financedAmountWTax.amount,
                    currency: "EUR"
                }
            }else{
                orderFinancingRequest.financedAmountWTax = {
                    amount: financingValue?.financedAmountWTax,
                    currency: "EUR"
                }
            }

        }
        else{
            orderFinancingRequest.financedAmountWTax = {
                amount: 0,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && (financingValue?.firstRent!=null &&financingValue?.firstRent!=undefined)) {
            if(financingValue?.firstRent.amount!=null && financingValue?.firstRent.amount!=undefined ){
                orderFinancingRequest.firstRent = {
                    amount: financingValue?.firstRent.amount,
                    currency: "EUR"
                }
            }else{
                orderFinancingRequest.firstRent = {
                    amount: financingValue?.firstRent,
                    currency: "EUR"
                }
            }

        }
        else{
            orderFinancingRequest.firstRent = {
                amount: 0,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && orderFinancingRequest.type && orderFinancingRequest.type.value) {
            orderFinancingRequest.type={objectType: "odm.order.orderfinancingtype",
            systemUid: "odm-order",
            resourceUid:orderFinancingRequest.type.value
            }
        }
        orderFinancingRequest.objectType = 'odm.order.orderfinancing'
        delete orderFinancingRequest?.scale
        delete orderFinancingRequest?.associatedParty
        delete orderFinancingRequest?.config
        delete orderFinancingRequest.financingProductPack
    }


    return orderFinancingRequest
}

export const addPartieToOrders = async (order: any,orderPartie?:any,roleType?:any) => {
    const orderClone = deepClone(order)
    for(const party of orderClone.orderParties){
        if (party.orderParty && party.orderParty.party && party.orderParty.party.rawData){
            delete party.orderParty.party.rawData.responseUrl
            delete party.orderParty.party.rawData.responseStatus
            delete party.orderParty.party.rawData.responseMethod
            if (party.orderParty.party.rawData.responseData){
                party.orderParty.party.rawData = party.orderParty.party.rawData.responseData
            }
                party.orderParty.party.rawData.creationDate ?  party.orderParty.party.rawData.creationDate = moment( party.orderParty.party.rawData.creationDate,'DD/MM/YYYY').format("YYYY-MM-DD") : null;
                party.orderParty.party.rawData.birthDate ?  party.orderParty.party.rawData.birthDate = moment( party.orderParty.party.rawData.birthDate,'DD/MM/YYYY').format("YYYY-MM-DD") : null;
            if (party.orderParty.party.rawData.roles){
                    for(const role of party.orderParty.party.rawData.roles){
                        if (role.validity && role.validity.from){
                            role.validity.from = moment( role.validity.from,'DD/MM/YYYY').format("YYYY-MM-DD")
                        }
                    }
            }
            if (party.orderParty.party.rawData.jobs){
                for(const job of party.orderParty.party.rawData.jobs){
                    if (job.validity && job.validity.from){
                        job.validity.from = moment( job.validity.from,'DD/MM/YYYY').format("YYYY-MM-DD")
                    }
                }
        }
        }
    }
    orderPartie.creationDate ?  orderPartie.creationDate = moment( orderPartie.creationDate,'DD/MM/YYYY').format("YYYY-MM-DD") : null;
    orderPartie.birthDate ?  orderPartie.birthDate = moment( orderPartie.birthDate,'DD/MM/YYYY').format("YYYY-MM-DD") : null;
        if (orderPartie.roles){
            for(const ro of orderPartie.roles){
                if (ro.validity && ro.validity.from){
                    ro.validity.from = moment( ro.validity.from,'DD/MM/YYYY').format("YYYY-MM-DD")
                }
            }
        }
        const associtedPartie :any={
            objectType: "odm.order.orderassociatedparty",
            systemUid: "odm-order",
            orderParty:{
                objectType: "odm.order.orderparty",
                systemUid: "odm-order",
                    role:{
                        objectType: "odm.order.orderpartyrole",
                        systemUid: "odm-order",
                        resourceUid:roleType
                    },
                    party:{
                        objectType: orderPartie && orderPartie.partyType === "party-FrenchPerson" 
                        ? "odm.party.party.person.frenchperson"
                        : orderPartie && orderPartie.partyType ==="party-FrenchOrganization"
                        ? "odm.party.party.organization.frenchorganization" : null,
                        systemUid: "odm-party",
                        resourceUid: orderPartie.resourceUid,
                        rawData: orderPartie
                    }
        }
    }
    delete associtedPartie.orderParty.party.rawData.partyType
    for(const orderItem of orderClone.orderItems){
        for(const asset of orderItem.orderAssets){
            if (asset.assetDelivery){
                asset.assetDelivery.initialDeliveryDate ? asset.assetDelivery.initialDeliveryDate = moment(asset.assetDelivery.initialDeliveryDate,'DD/MM/YYYY').format("YYYY-MM-DD") : null;
                asset.assetDelivery.previsionDeliveryDate ? asset.assetDelivery.previsionDeliveryDate = moment(asset.assetDelivery.previsionDeliveryDate,'DD/MM/YYYY').format("YYYY-MM-DD") : null;
                asset.assetDelivery.effectiveDeliveryDate ? asset.assetDelivery.effectiveDeliveryDate= moment(asset.assetDelivery.effectiveDeliveryDate,'DD/MM/YYYY').format("YYYY-MM-DD") : null;
            }
            if (asset.supplier 
                && asset.supplier.party 
                && asset.supplier.party.rawData 
                && asset.supplier.party.rawData.responseData 
                && asset.supplier.party.rawData.responseData.roles){
                    for(const ro of asset.supplier.party.rawData.responseData.roles){
                        if (ro.validity && ro.validity.from){
                            ro.validity.from = moment( ro.validity.from,'DD/MM/YYYY').format("YYYY-MM-DD")
                        }
                    }

            }
         }
     }
     orderClone.effectiveDeliveryDate ? orderClone.effectiveDeliveryDate = moment(orderClone.effectiveDeliveryDate,'DD/MM/YYYY').format("YYYY-MM-DD") : null;
     orderClone.previsionDeliveryDate ? orderClone.previsionDeliveryDate = moment(orderClone.previsionDeliveryDate,'DD/MM/YYYY').format("YYYY-MM-DD") : null;
    orderClone.orderParties.push(associtedPartie)
    const orderUpdated = await createOrderOrch(orderClone)
    return orderUpdated
}
function handleRole(roleCode:string){
    const roles=[]
    const newRole={
        role: {
          resourceUid: roleCode,
        },
        status: {
          resourceUid: "ACTIVATED",
          objectType: "odm.party.rolestatus"
        },
        validity: {
          from: moment().format('DD/MM/YYYY'),
          until: ""
        },
        nonSolicitation: false,
        daaq: "/",
        preferences: [],
        objectType: "odm.party.partyrole"
      }
      roles.push(newRole)
return roles
}