import { ActionContext, Module } from "vuex";
import { ActionType, InventoryStatusEnum, Task, TaskDefinition, TaskModule, TaskState } from "@/types";
import {
  abandonTask,
  buildReserveOpinion,
  executeAction,
  executeOrderAction,
  getGroupsByUserId,
  getRoles,
  getTasks,
  getWaitTask,
  saveOfferFromTask,
  takeChargeTask,
  setNbrReceivedDocuments,
  getTaskListByUserStatus
} from "@/store/services/task/taskService";
import decisionModule from "@/store/modules/task/actions/decisionModule";
import analysisModule from "@/store/modules/task/actions/analysisModule"
import uploadDocumentModule from "@/store/modules/task/actions/uploadDocumentModule";
import Notify from 'quasar/src/plugins/Notify.js';;
import { getOffer } from "@/store/services/offerService";
import partyModule from "@/store/modules/task/actions/partyModule";
import financingRequestModule from "@/store/modules/task/actions/financingRequestModule";
import { deepClone, DownloadAllDocuments, globalConfig, Masks, parseTaskVariables, PreconditionAction, SendAllDocuments, taskVariables } from "@/utils";
import validateDocumentsModule from "@/store/modules/task/actions/validateDocumentsModule";
import { getGatheringProcessList } from "@/store/services/task/actions/validateDocumentService";
import printOfferModule from "@/store/modules/task/actions/printOfferModule";
import store from "@/store";
import router from "@/router";
import i18n from "@/i18n";
import modifyConditionsModule from "./actions/modifyConditionsModule";
import { getOrderEditMode, getOrderParties, saveOrder } from "@/store/services/order/orderService";
import { getTeamMembersByManagerCode } from "@/components/task/ts";
import { assignTask, sendMessage, unClaimTask } from "@/store/services/task/workflow";
import startOrderModule from "@/store/modules/task/actions/order/startOrderModule";
import validationOrderModule from "@/store/modules/task/actions/order/validationOrderModule";
import { setListDecision } from "@/store/services/middle/middleOfficeService";
import { getCheckPointsGathering, getDocumentValueConfig, getGatheringProcessFromOrder, saveCheckPointsValue, updateDocumentValue, updatefieldValue } from "@/store/services/task/actions/order/completePreconditionsService";
import {
  getGatheringProcessFromOffer,
  updateCheckPointsStatus
} from "@/store/services/task/actions/completePreconditionsService";
import { getProcess } from "@/store/services/task/actions/uploadDocumentService";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import printDocumentModule from "@/store/modules/document/printDocumentModule";
import { saveOrderOrch } from "@/store/services/order/orderOrch";
import { processOrderMass } from "@/commons/gathering-process-order";

const taskModule: Module<TaskState, any> = {
  namespaced: true,
  state: {
    startOrderModule: {
      bind: {}
    },
    validationOrderModule: {
      bind: {}
    },
    module: TaskModule.ORDER,
    analysisModule: {},
    decisionModule: {},
    modifyConditionsModule: {},
    tasks: [],
    comment: "",
    selectedTask: {},
    searchTask: [],
    gatheringProcess: [],
    gatheringProcessOrder:[],
    nbrReceivedDocuments: "",
    group: {},
    roles: [],
    groups: [],
    filterByOfferReference: '',
    validateDocumentsModule: {
      gatheringProcessList: []
    },
    printOfferModule: {
      templates: [],
      emailSendList: [],
      body: '',
      showButtonsPreview: false,
      showButtonValidate: false
    },
    members: [],
    actions: [],
    isTaskContinueLoading: false,
    isTaskQuitLoading:false,
    delegationLvlProcess: '',
    updateDelegationLvlProcess: '',
    assignReassign: {
      teamMember: '',
      selectedTasks: []
    },
    taskStatus:[],
    visibleColumns: [
      "businessKey",
      "module",
      "firstName",
      "assignee",
      "actionDate",
      "dueDate",
      "status",
      "taskName"
    ],
    previousRoute: undefined
    },
  getters: {
    getProposalSelected(state) {
      return state.offer?.proposals.filter(proposal => proposal.flagSelected)[0]
    },
    isTaskEnterCustomerDecision(state) { return state.selectedTask.taskDefinition === TaskDefinition.ENTER_CUSTOMER_DECISION },
    isTaskCompleteOfferInfo(state) { return state.selectedTask.taskDefinition === TaskDefinition.COMPLETE_OFFER_INFO },
    isTaskUploadDocuments(state) { return state.selectedTask.taskDefinition === TaskDefinition.UPLOAD_DOCUMENTS },
    isTaskSendFinancingRequest(state) { return state.selectedTask.taskDefinition === TaskDefinition.STEP_START_FINANCING_REQUEST },
    isTaskValidateDocuments(state) { return state.selectedTask.taskDefinition === TaskDefinition.VALIDATE_DOCUMENTS },
    isTaskTypeMonoDecision(state) { return state.selectedTask.taskDefinition === TaskDefinition.ENTER_DECISION },
    isTaskPrintDocument(state) { return state.selectedTask.taskDefinition === TaskDefinition.PRINT_DOCUMENT },
    isTaskSignContract(state) { return state.selectedTask.taskDefinition === TaskDefinition.SIGN_CONTRACT },
    isTaskEnterOpinion(state) {
      return Masks.regexOpinion.test(state.selectedTask.taskDefinition as string)
    },
    isTaskAnalysis(state) { return state.selectedTask.taskDefinition === TaskDefinition.ANALYSIS },
    isTaskDelegationLevel(state) { return state.selectedTask.taskDefinition === TaskDefinition.DELEGATION_LEVEL },
    isTaskModifyConditions(state) { return state.selectedTask.taskDefinition === TaskDefinition.MODIFY_CONDITIONS },
    isTaskStartOrder(state) { return state.selectedTask.taskDefinition === TaskDefinition.START_ORDER },
    isTaskCompletePreconditions(state) { return state.selectedTask.taskDefinition === TaskDefinition.COMPLETE_PRECONDITIONS },
    isTaskValidateOrder(state) { return state.selectedTask.taskDefinition === TaskDefinition.VALIDATE_ORDER },
    isTaskOrderCommission(state) { return state.selectedTask.taskDefinition === TaskDefinition.ORDER_COMMISSION },
    isTaskModuleOffer(state) { return state.module === TaskModule.OFFER },
    isTaskModuleOrder(state) { return state.module === TaskModule.ORDER },
    taskDefinitionAllowedToSave() {
      return [
        //To add all necessary have to save an offer
        TaskDefinition.COMPLETE_OFFER_INFO,
        TaskDefinition.ANALYSIS,
        TaskDefinition.MODIFY_CONDITIONS
      ]
    },
    isTaskIsAssignedToConnectedUser(state) {
      const prevState = localStorage.getItem(globalConfig.storageKeys.authModule)
      if (prevState) {
        const connectedUser = JSON.parse(prevState)
        if (state.selectedTask.assignee) {
          const possibleAssignedType1 = `${connectedUser.user.firstName} ${connectedUser.user.lastName}`
          const possibleAssignedType2 = `${connectedUser.user.firstName} ${connectedUser.user.lastName} ## ${connectedUser.user.username}`
          if (state.selectedTask.assignee === possibleAssignedType1 || state.selectedTask.assignee === possibleAssignedType2) {
            return true
          }
        }
      }
      return false
    },
    taskDefinition(state) {
      return state.selectedTask.taskDefinition
    },
    getReserveOpinion() {
      return {
        objectType: "odm.decision.elementreserve.opinionreserve",

        systemUid: "odm-decision",
        businessData: null,
        daaq: "/",
        comment: "",
        reserve: {}
      }
    },
    initOpinion() {
      return {

        objectType: "odm.decision.opinionmaker",
        systemUid: "odm-decision",
        businessData: null,
        daaq: "/",
        opinionMakerId: null,
        comment: "",
        opinion: {},
        reserves: [],
        opinionMaker: "",
        opinionDate: ""
      }
    },
    getOrder(state){
      return state.order;
    },     
    getOffer(state) {
      return state.offer;
    },
    getNbrReceivedDocuments(state){
      return state.nbrReceivedDocuments;
    },
    getVisibleColumns(state){
      return state.visibleColumns
    },
  },
  actions: {
    setModule({ commit }, payload) {
      if (!payload || !payload.module) {
        throw new Error('Module is mandatory!')
      }
      const { module } = payload
      commit("setModule", module)
    },
    getTasks({ commit }, payload) {
      const { offset, limit, taskCriteria, callback } = payload;
      getTasks(taskCriteria, offset, limit).then((response) => {
        const tasks = response.data
        commit("setTasks", tasks)
        if (!this.state.taskModule.actions.length) {
          const actions = tasks.filter((task: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t.taskDefinition === task.taskDefinition)
          ).map((tk: any) => tk.taskDefinition);
          commit("setActions", actions)
        }
        if (callback) {
          callback(response)
        }
      });
    },
    getTasksStatus({ commit }, payload) {
      const { offset, limit, taskCriteria, callback } = payload;
      getTaskListByUserStatus(taskCriteria).then((response) => {
        commit("setTasksStatus", response)
      });
    },
    setActions({ commit }, payload) {
      commit("setActions", payload)
    },
    setTaskStatus({ commit }, payload) {
      commit("setTaskStatus", payload)
    },
    setTaskOfferStatus({ commit }, payload) {
      const clone = deepClone(payload.data.variables)
      const variables = JSON.parse(clone)
      const value = payload.context === "print" ? variables._offerStatus.value : variables.offerStatus.value
      this.state.taskModule.selectedTask.variables.offerStatus = value
    },
    
    async executeGetWaitTask(context, payload) {
      const { taskFilter, taskDefinition, onSuccess, onFail } = payload
      if (taskFilter) {
        const tasks = await getWaitTask(taskFilter, taskDefinition)
        if (tasks) {
          console.log("success tasks",tasks)
          if (onSuccess) onSuccess(tasks)
        } else {
          console.log("success tasks", tasks)
          if (onFail) onFail()
        }
      }
    },
    async getFutureTaskByReferenceAndTaskDefinition({ dispatch }, payload) {
      const { orderReference, offerReference, taskDefinition, onSuccess, onFail } = payload
      if (!orderReference && !offerReference) {
        throw new Error('At least one reference is required')
      }
      const refKey = orderReference ? 'orderReference' : 'offerReference'
      const refValue = orderReference ? orderReference : offerReference
      return dispatch('executeGetWaitTask', {
        onSuccess,
        onFail,
        taskDefinition,
        taskFilter: {
          searchVariables: JSON.stringify([
            { name: refKey, operator: "eq", value: refValue }
          ])
        }
      })
    },
    getSelectedTaskOrderDetail({ commit, dispatch, getters }, payload) {
      const { task } = payload
      const variables = task.variables
      task.variables = parseTaskVariables(variables, task.taskDefinition)
      const orderId = task.variables.orderId
      commit("setModule", TaskModule.ORDER)
      commit("setTask", task)
      dispatch('setOrder', { orderId, 
        callback: () => {
            if (payload.callback){
              payload.callback()
            }
          } 
      })
    },
    getSelectedTask({ commit, dispatch }, payload) {
      const { task } = payload
      let orderReference = this.state.taskModule?.order?.reference
      if (task.variables && task.variables.orderReference && task.variables.orderReference.value) {
        orderReference = task.variables.orderReference.value
      }
      const taskFilter = {
        includeAssignedTasks: true,
        searchVariables: JSON.stringify([{
          name: "orderReference",
          operator: "eq",
          value: orderReference,
        }]),
        initializeVariables: "module;orderId;orderReference;orderCreationDate;orderStatus;offerId;offerStatus;offerReference;customerName;_offerDecisionProcessId;_clientDecisionProcessId;associated_networks_id;customerRessourceUid;customerBirthDate;offerCreationDate;associated_network_nodes_id;customerType;offerPhase;customerReference;applicationName;analysisProcessId;_decisionAnalysisProcessId;additional_action_items;SignatureMethod;orderExternalReference;activity"
      }
      getTasks(taskFilter).then((response) => {
        const variables = JSON.parse(response.data[0].variables)
        task.variables = parseTaskVariables(variables, response.data[0].taskDefinition)
        task.resourceUid = task.variables.decisionProcessId
        commit("setTask", task)
        if (task.variables && task.variables.module) {
          commit("setModule", task.variables.module)
        }
        if (task.variables && task.variables.orderId) {
          const orderId = task.variables.orderId
          dispatch('setOrder', { orderId, callback: () => payload.callback() })
        }
      });
    },
    gatheringProcess: ({ commit }, payload) => {
      const { request } = payload;
      commit("setGatheringProcess", request.gatheringProcess);
    },
    setNbrReceivedDocuments: ({ commit,state }) => {
     const nbrReceivedDoc =  setNbrReceivedDocuments(state.gatheringProcess);
      commit("setNbrReceivedDocuments", nbrReceivedDoc);
    },
    gatheringProcessOrder: ({ commit }, payload) => {
      const { request } = payload;
      if(request.gatheringProcess ){
        request.gatheringProcess.sort((a:any, b: any) => {
            return PreconditionAction.indexOf(a.assessmentContext) - PreconditionAction.indexOf(b.assessmentContext);
        });
    }
      commit("setGatheringProcessOrder", request.gatheringProcess);
    },
    getOrderProcess: ({ commit }, payload) => {
      const { request, callback } = payload
      getGatheringProcessFromOrder(request.variable).then(response => {
        if (callback) {
          callback(response)
        }
      })
    },
    getOfferProcess: ({ commit, state }, payload) => {
      const { callback } = payload
      const checkPointsId = state.selectedTask.variables._checkPointsId
      getProcess(checkPointsId).then(response => {
        commit("setGatheringProcess", [response]);
        if (callback) {
          callback([response])
        }
      })
    }
    /*getOfferProcess: ({ commit, state }, payload) => {
      const { request, callback } = payload
      getGatheringProcessFromOffer(request.variable).then(response => {
        if (callback) {
          callback(response)
        }
      })
    }*/,
    getOrderCheckPoints: ({ commit }, payload) => {
      const { request, callback } = payload
      getCheckPointsGathering(request.resourceUid).then(response => {
        if (callback) {
          callback(response)
        }
      })
    },
    updateDocumentValue: ({ commit }, payload) => {
      const { request, callback } = payload
      updateDocumentValue(request).then(response => {
        if (callback) {
          callback(response)
        }
      })
    },
    getDocumentValueConfig: ({ commit }, payload) => {
      const { request, callback } = payload
      getDocumentValueConfig(request).then(response => {
        if (callback) {
          callback(response)
        }else{
          return response
        }
      })
    },
    updateFieldValue: ({ commit }, payload) => {
      const { request, callback } = payload
      updatefieldValue(request).then(response => {
        if (callback) {
          callback(response)
        }
      })
    },

    listDecision({ commit }, payload) {
      const { listDecision, callback } = payload;
      setListDecision(listDecision).then((response: any) => {
        commit("setListDecision", response)
        callback()
      });
    },

    showDialog: ({ commit }, payload) => {
      const { request } = payload;
      commit("setShowDialog", request.index);
    },
    listReserveOpinion({ commit }, payload) {
      const { reservesOptions } = payload;
      buildReserveOpinion(reservesOptions).then((response: any) => {
        commit("listReserveOpinion", response)
      });
    },
    getRoles({ commit }) {
      getRoles()
        .then((res: any) => commit("setRoles", res))
        .catch((e: any) => console.error(e));
    },
    getGroups({ commit }) {
      const userConnected = this.getters["authModule/getUserConnected"]
      getGroupsByUserId(userConnected.userId)
        .then(res => commit("setGroups", res))
        .catch((e: any) => console.error(e));
    },
    getMembers({ commit }) {
      const userConnected = this.getters["authModule/getUserConnected"]
      getTeamMembersByManagerCode(userConnected.userId)
        .then(res => commit("setMembers", res))
        .catch((e: any) => console.error(e));
    },
    setOrder({ commit }, payload) {
      if (!payload || !payload.orderId) {
        throw new Error('orderId is mandatory')
      }
      getOrderEditMode(payload.orderId).then(order => {
        // TODO - EF-161 finish
        commit('setOrder', order)
        if (payload.callback) payload.callback(order)
      })
    },
    async saveOrder({ state, dispatch }, payload) {
      if(state.order){
        const order:any =state.order
        const orderParties = await getOrderParties(order.orderParties)
        saveOrderOrch(order, orderParties)
        .then((res: any) => {
            if (payload && payload.callback) {
                payload.callback(res)
            }
        })
        .catch(e => {
            Notify.create({
                message: 'ERROR: ' + e,
                color: 'negative'
            });
        })
      }
      
  },
    setOrderUpdate({ commit }, payload) {
      if (!payload || !payload.resourceUid) {
        throw new Error('orderId is mandatory')
      }
        commit('setOrder', payload)

    },
    setOffer({ commit }, payload) {
      const { offerId } = payload
      if (!offerId) {
        throw new Error('OfferId is required')
      }
      getOffer(offerId, this.state).then(response => {
        const { offer } = response
        if (this.state.taskModule.selectedTask.taskDefinition === TaskDefinition.ANALYSIS || this.state.taskModule.selectedTask.taskDefinition === TaskDefinition.MODIFY_CONDITIONS) {
          const { offer, offerAssets, proposalScale } = response
          store.commit('demandModule/setOffer', offer);

          //-------------------------------------
          getGatheringProcessList(offerId)
            .then((res) => store.commit("middleOfficeModule/setGatheringProcessList", res))
            .catch((e) => console.error(e));
          //-------------------------------------
          if (offerAssets && offerAssets.length) {
            store.commit('demandModule/setProposals', offer.proposals)
            store.dispatch('demandModule/updateAssetState', { offerAssets: offerAssets })
            if (proposalScale) {
              store.dispatch('demandModule/setProposalScale', { proposalScale })
            }

            store.dispatch('financingModule/calculateProposalAssetCarAmounts', { skipRecalculateScaleSelected: true })
            store.commit('demandModule/setIsEdit', true)
            store.dispatch('demandModule/setFirstTab', false)
          }

        }

        commit('setOffer', offer)
        //dispatch the current context with the offer that was retrieved from the server
        this.dispatch("setCurrentContext", offer);
        //-------------------------------------
        if (payload.callback) payload.callback(offer)
      }).catch(err => {
        console.error(err)
        Notify.create({
          message: err.message,
          color: 'negative'
        });
      })
    },
    saveOffer({ commit }) {
      this.dispatch('controlsModule/evaluateActionsControls', {
        onSuccess: () => {
          const offer = this.state.taskModule.offer
          saveOfferFromTask(offer)
            .then(response => {

              commit('setOffer', response)

              if (this.getters["taskModule/isTaskAnalysis"]) {
                store.dispatch("analysisModule/updateAnalysis").then(() => {
                  Notify.create({
                    message: `Succès: l'analyse a été sauvée!`,
                    color: "positive",
                  });
                });
              }
            })
            .catch(err => {
              console.error(err)
              Notify.create({
                message: err,
                color: 'negative'
              });
            })
        },
        onFail: (message: string) => {
          Notify.create({
            message,
            color: "warning",
          });
        },
        entity: 'Offer'
      })
    },
    async executeOrderAction({ commit, dispatch, state }, payload) {
      const { selectedTask } = state
      const { actionType } = payload
      if (!actionType) {
        throw new Error('ActionType is mandatory!')
      }
      const userConnected = this.getters["authModule/getUserConnected"]
      executeOrderAction(this.state.taskModule, userConnected, actionType)
        .then(response => {
          if (payload && payload.callback) payload.callback(response)
          if (response) commit('setExecutedActionEvent', response)
        })
        .catch(err => {
          console.error(err)
          Notify.create({
            message: err.message || err.error || err,
            color: 'negative'
          });
        })
    },
    async executeOrderActionStartOrder({ commit, dispatch, state }, payload) {
      const { selectedTask } = state
      const { actionType } = payload
      if (!actionType) {
        throw new Error('ActionType is mandatory!')
      }
      const userConnected = this.getters["authModule/getUserConnected"]
      if(state.order){
        const order:any =state.order
        const orderParties = await getOrderParties(order.orderParties)
        saveOrderOrch(order, orderParties)
            .then((res: any) => {
              commit('setOrder', res)
          executeOrderAction(this.state.taskModule, userConnected, actionType)
            .then(response => {
              if (payload && payload.callback) payload.callback(response)
              if (response) commit('setExecutedActionEvent', response)
            })
            .catch(err => {
              console.error(err)
              Notify.create({
                message: err.message || err.error || err,
                color: 'negative'
              });
            })

            })
            .catch(e => {
                Notify.create({
                    message: 'ERROR: ' + e,
                    color: 'negative'
                });
            })
      }
      
    }
    ,
    async executeAction({ commit, dispatch }, payload) {
      const { actionType, currentTask } = payload
      if (!actionType) {
        throw new Error('ActionType is mandatory!')
      }
      if (actionType == ActionType.DOWNLOAD_DOCUMENT) {
        commit('setShowLoadingDownload', true)
      }
      const userConnected = this.getters["authModule/getUserConnected"]
      await this.dispatch('controlsModule/evaluateActionsControls', {
        onSuccess: () => executeAction(this.state.taskModule, userConnected, actionType)
          .then(response => {
         
            if (actionType == ActionType.SEND_DOCUMENT) {
              commit('setShowSendTemplate', true)
              SendAllDocuments()
            }
            if (actionType == ActionType.DOWNLOAD_DOCUMENT) {
              DownloadAllDocuments()
              // commit('checkDownload', true)
            }
            if (payload && payload.callback) payload.callback(response)
            if (response) commit('setExecutedAction', actionType)
              if (this.getters["taskModule/isTaskPrintDocument"]) {
                const assets = this.state.taskModule.offer?.proposals?.[0]?.proposalItems?.[0]?.proposalAssets;
                if (assets) {
                  assets.forEach((asset:any) => {
                    if (asset.config?.productAssetModel?.config?.inventoryItems?.length > 0) {
                      const inventoryItems = asset.config.productAssetModel.config.inventoryItems;
                      inventoryItems.forEach((item:any) => {
                        store.dispatch("orderModule/updateInventoryItem", {
                          inventoryItemCode: item.resourceUid,
                          status: InventoryStatusEnum.SOLD
                        });
                      });
                    }
                  });
                }
              }
            if (this.state.taskModule.selectedTask?.proceed) {
              commit('setTaskContinueLoading', true)
              store.dispatch('taskModule/getFutureTaskByReferenceAndTaskDefinition', {
                offerReference: this.state.taskModule.offer.reference,
                taskDefinition: this.state.taskModule.selectedTask.taskDefinition,
                onSuccess: async (task: Task) => {
                  const variables = task.variables
                  task.variables = parseTaskVariables(variables, task.taskDefinition)
                  await dispatch('claimOwnership', {
                    task: task
                  })
                  .then(() => {

                    commit('setTaskContinueLoading', false)

                  })
                },
                onFail: () => {
                  store.dispatch("taskModule/cleanState", {
                    isOrder: true
                  });
                  commit('setTaskContinueLoading', false)
                  commit('setProceed', false)
                  router.push("/task/list");
                }
              })
            }
          })
          .catch(err => {
            console.error(err)
            Notify.create({
              message: err.message || err.error || err,
              color: 'negative'
            });
          }),
        onFail: (message: string) => {
          Notify.create({
            message,
            color: "warning",
          });
        },
        entity: currentTask
      })
    },
    claimOwnership({ commit, dispatch, getters }, payload) {
      const { task, callback, blockRedirection } = payload
      const userConnected = this.getters["authModule/getUserConnected"]
      const userId = `${userConnected.firstName} ${userConnected.lastName} ## ${userConnected.username}`
      takeChargeTask(task.taskId, userId).then(() => {
        task.assignee = `${userConnected.firstName} ${userConnected.lastName}`
        commit('setTaskAssignedUser', task)
        task.isLoading = true;
        if (blockRedirection) {
          task.isLoading = false
        }
        else {
        
          if (getters.isTaskModuleOffer) {
            dispatch("getSelectedTask", {
              task,
              callback: () => {
                if (callback) callback()
                router.push("/task/detail").then(() => (task.isLoading = false));
              },
            });
          } else {
            dispatch("getSelectedTaskOrderDetail", {
              task,
              callback: () => {
                
                  router.push("/task/detail").then(() => (task.isLoading = false));
                
              },
            });
          }
        }
       
      }).catch(err => {
        console.error(err)
        Notify.create({
          message: i18n.global.t("main.register.claimedTask"),
          color: 'negative'
        })
      }
      )


    },
    checkForm({ commit }, payload) {
      const { valid } = payload
      commit('setcheckForm', !valid)
    },
    unclaimOwnership({ commit }, payload) {
      const { task } = payload
      const userConnected = this.getters["authModule/getUserConnected"]
      const userId = `${userConnected.firstName} ${userConnected.lastName} ## ${userConnected.username}`
      task.assignee = null
      commit('setTaskAssignedUser', task)
      abandonTask(task.taskId, userId).catch(err => {
        console.error(err)
        Notify.create({
          message: err.message || err.error || err,
          color: 'negative'
        });
      })
    },
    setFilterByOfferReference({ commit }, payload) {
      commit('setFilterByOfferReference', payload)
    },
    setFormValid({ commit }, payload) {
      const { valid } = payload
      commit('setFormValid', !!valid)
    },
    setIsFormValid({ commit }, payload) {
      const { valid } = payload
      commit('setIsFormValid', !!valid)
    },
    updateOffer({ commit }, payload) {
      const { offer } = payload
      if (offer) {
        commit('setOffer', offer)
      }
    },
    cleanState({ commit, dispatch }, payload) {
      const { isOrder } = payload
      dispatch('decisionModule/cleanState')
      dispatch('printOfferModule/cleanState')
      dispatch('partyModule/cleanState')
      dispatch('startOrderModule/cleanState')
      dispatch('validationOrderModule/cleanState')
      if (!isOrder) {
        this.dispatch('summaryPanelModule/cleanState')
      }
      dispatch('validateDocumentsModule/cleanState')
      dispatch('modifyConditionsModule/cleanState')
      dispatch('printDocumentModule/cleanState')

      commit('setInitial', isOrder)
    },
    assignTask(actionContext: ActionContext<TaskState, unknown>, payload) {
      const { memberCode, taskInfos, callback } = payload;
      taskInfos.forEach(async (task: { taskId: string, assignee: string, variables: { offerReference: { value: string } } }) => {
        const { taskId, assignee, variables } = task;
        if (assignee) {
          const taskFilter = {
            searchVariables: JSON.stringify([{
              name: "offerReference",
              operator: "eq",
              value: variables.offerReference.value || '',
            }]),
            initializeVariables: taskVariables.offer,
            includeAssignedTasks: true
          }
          getTasks(taskFilter).then(async res => {
            if (assignee === res.data[0].assignee) {
              await unClaimTask({ userId: assignee, taskId }).catch((e: Error) => console.error(e));
              await assignTask({ userId: memberCode, taskId }).catch((e: Error) => console.error(e));
              callback();
            }
          })
        }
        else {
          await assignTask({ userId: memberCode, taskId }).catch((e: Error) => console.error(e));
          callback();
        }
      })
    },
    setTaskContinueLoading({ commit }, payload) {
      commit('setTaskContinueLoading', payload)
    },
    setTaskQuitLoading({ commit }, payload) {
      commit('setTaskQuitLoading', payload)
    },
    updateCheckPointsStatus: ({ commit }, payload) => {
      const { request } = payload
      updateCheckPointsStatus(request).then().catch((e: Error) => console.error(e))
    },
    setAssociatedParties({ commit }, payload) {
      commit('setAssociatedParties',payload)
    },
    async sendMessage({ commit }, payload) {
      const { messageTosend, module,target,callback } = payload
      await sendMessage(messageTosend).then((res: any) => {
        if (target) {
          if (module === "Offer") {
            const variables = JSON.parse(res.data[0].variables)
            this.state.taskModule.selectedTask.variables.offerStatus = variables.offerStatus.value
            store.dispatch("releaseLockState").then(() => {
              router.push(target)
            })
          }
          else if (module === "Order") {
            store.dispatch("releaseLockState").then(() => {
              router.push(target)
            })
          }
        }

        if (callback) {
          callback();
        }


          
        })
        .catch(e => console.error(e))
    },
    saveCheckPointsValue: ({ getters }, payload) => {
      const { request } = payload
      const { objectType, resourceUid, fieldValue } = request
      if (getters.isTaskModuleOrder){
        const orderInfo = JSON.stringify(getters.getOrder);
        const checkPointData = {
          objectType,
          resourceUid,
          fieldValue,
          orderInfo
        }
        saveCheckPointsValue(checkPointData).catch(() =>
          Notify.create({
          message: 'Save Checkpoint value error',
          color: 'negative'
        }));
      } 
    },
    setShowLoadingDownload({ commit },payload) {
      commit("setShowLoadingDownload",payload)
    },
    async UploadDocumentsMass({ commit },payload) {
      const {listOrder}=payload
      await processOrderMass(listOrder).then((response:any) => {
        commit("setUploadDocumentsMass",response)
        console.log(response)
      }).catch((e:any) => {
        Notify.create({
            message: 'ERROR: ' + e,
            color: 'negative'
        });
    })

    },
  setVisibleColumns({ commit }, payload) {
      const {visibleColumns } = payload
      commit("setVisibleColumns", visibleColumns);
  },
  recoverState({ commit }, payload) {
      const selectedTask = localStorage.getItem(globalConfig.storageKeys.taskModule)
        if (selectedTask) {
            const task = JSON.parse(selectedTask).selectedTask
              if (task){
                console.log("task ",task)
                store.dispatch("taskModule/getSelectedTaskOrderDetail", {
                  task: task
                })
              }
        }
    },
    setPreviousRoute({ commit }, payload) {
      const {previousRoute } = payload
      commit("setPreviousRoute", previousRoute);
  }
   
  
  },
  mutations: {
    setInitial(state, payload) {
      state.tasks = []
      state.selectedTask = {}
      state.searchTask = []
      state.gatheringProcess = []
      state.gatheringProcessOrder = []
      state.group = {}
      state.roles = []
      state.actions = []
      state.members = []
      state.groups = []
      delete state.offer
      delete state.taskDefinition
      delete state.executedAction
      state.isTaskContinueLoading = false
      state.module = payload ? TaskModule.ORDER : TaskModule.OFFER
    },
    setModule(state, payload) {
      state.module = payload;
    },
    setOrder(state, payload) {
      state.order = payload
    },
    setExecutedAction(state, payload) {
      state.executedAction = payload;
    },
    setExecutedActionEvent(state, payload) {
      state.executedActionEvent = payload;
    },
    setFormValid(state, payload) {
      state.formValid = payload
    },
    setIsFormValid(state, payload) {
      state.isFormValid = payload
    },
    setShowSendTemplate(state, payload) {
      state.printOfferModule.showSendTemplate = payload
    },
    setShowLoadingDownload(state, payload) {
      state.printOfferModule.showLoadingDownload = payload
    },
    setOffer(state, payload) {
      state.offer = payload
    },
    setListDecision(state, payload) {
      state.decisions = payload
    },
    listReserveOpinion(state, payload) {
      state.Openionreserves = payload
    },
    setcheckForm(state, payload) {
      state.checkForm = payload
    },
    setTasks(state, payload) {
      state.tasks = []
      const tasks = payload.map((task: Task) => {
        if (task.variables && typeof task.variables === "string") {
          task.variables = JSON.parse(task.variables);
        }
        return task;
      });
      state.tasks = deepClone(tasks)
    },
    setTask(state, payload) {
      state.selectedTask = payload;
    },
    setGatheringProcess(state, payload) {
      state.gatheringProcess = payload;
    },
    setGatheringProcessOrder(state, payload) {
      state.gatheringProcessOrder = payload;
    },
    setNbrReceivedDocuments(state,payload){
      state.nbrReceivedDocuments = payload
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
    setGroups(state, payload) {
      state.groups = payload;
    },
    setShowDialog(state, payload) {
      state.gatheringProcess[payload].showDialog =
        !state.gatheringProcess[payload].showDialog;
    },
    setFilterByOfferReference(state, payload) {
      state.filterByOfferReference = payload;
    },
    checkDownload(state, payload) {
      state.printOfferModule.showButtonValidate = payload
    },
    setTaskAssignedUser(state, payload) {
      state.selectedTask = payload
    },
    setMembers(state, payload) {
      state.members = payload;
    },
    setActions(state, payload) {
      state.actions = payload
    },
    setTaskContinueLoading(state, payload) {
      state.isTaskContinueLoading = payload
    },
    setTaskQuitLoading(state, payload) {
      state.isTaskQuitLoading = payload
    },
    setProceed(state, payload) {
      state.selectedTask.proceed = payload
    },
    setShowPopupDialog(state, payload) {
      state.selectedTask.showPopupDialog = payload
    },
    setSelectedAction(state, payload) {
      state.selectedTask.selectedAction = payload
    },
    setTeamMember(state, payload) {
      state.assignReassign.teamMember = payload
    },
    setSelectedTasks(state, payload) {
      state.assignReassign.selectedTasks = payload
    },
    setTaskStatus(state, payload) {
      state.selectedTask.variables.offerStatus = payload
    },
    setAssociatedParties(state, payload) {
      state.selectedTask.associatedParties = payload
    },
    setTasksStatus(state, payload){
      state.taskStatus = payload
    },
    setUploadDocumentsMass(state, payload){
      state.UploadDocumentsMass = payload
    },
    setVisibleColumns(state, payload) {
      state.visibleColumns = payload;
    },
    setPreviousRoute(state, payload) {
        state.previousRoute = payload;

    }
  },
  modules: {
    decisionModule,
    uploadDocumentModule,
    partyModule,
    financingRequestModule,
    validateDocumentsModule,
    printOfferModule,
    analysisModule,
    modifyConditionsModule,
    startOrderModule,
    validationOrderModule,
    printDocumentModule
  },
};

export default taskModule;
